<template>
  <main>
    <main-layout itemMenuActive="17" tituloMenu="Transacciones por usuario">
      <div class="container">
        <br/>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <form v-on:submit.prevent="getTransactions">
                  <h2>Reporte de transacciones por usuario</h2>
                  <div style="display: flex">
                    <div class="column is-3">
                      <b-field label="Fecha:" :message="validateForm.fecha.message" :type="validateForm.fecha.type">
                        <b-datepicker
                            placeholder="Seleccione fecha..."
                            v-model="model.fecha"
                            range :min-date="minDate" :max-date="maxDate">
                        </b-datepicker>
                      </b-field>
                    </div>
                    <div class="column is-3">
                      <b-field label="Tipo de documento" :message="validateForm.tipo.message"
                               :type="validateForm.tipo.type">
                        <b-select ref="tipoSelect" placeholder="Tipo de documento" v-model="model.tipo" v-on:change.native="cambioTipo">
                          <option :key="key" :alfanumerico="value.alfa" :value="value.tipo" v-for="(value, key) in documentTypes">{{ value.text }}</option>
                          <!--                            <option value="cc">Cedula de Ciudadanía</option>-->
                          <!--                            <option value="ce">Cedula de Extranjería</option>-->
                          <!--                            <option value="nit">NIT</option>-->
                        </b-select>
                      </b-field>
                    </div>
                    <div class="column is-5">
                      <b-field label="Número de documento:" :message="validateForm.cedula.message"
                               :type="validateForm.cedula.type">
                        <b-input 
                        icon="magnify"
                        ref="numeroDocumento"
                        pattern="[0-9]+"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        name="subject" expanded 
                        v-model="model.cedula" :controls="false"></b-input>
                        <p class="control">
                          <b-button type="is-primary" label="Consultar" native-type="submit"/>
                        </p>
                      </b-field>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div v-if="user" class="media">
                    <div class="media-content">
                      <p class="subtitle is-4">Se está consultando el usuario con {{ user.documentType }}:
                        {{ user.document }} </p> 
                    </div>
                    <div v-if="login.length > 0 || data.length>0|| face.length>0 ||creacion.length > 0 || cambio.length > 0 || recuperar.length > 0" class="uk-text-right">
                      <label class="uk-form-label"></label>
                      <b-button v-on:click="exportExcel">XLSX</b-button>
                    </div>
                </div>
                <hr>

                <div v-if="data.length > 0" class="uk-text-right">
                  <label class="uk-form-label"></label>
                  <b-button v-on:click="exportCSV">CSV</b-button>
                </div>

                <TableCard title="Consulta Onboarding Digital" :haveFilter="true" :columns="columnsOnboardingTable" :info="data"
                           detailKey="id" :configFilter="configFilterOnboarding"
                           :tableType="4"></TableCard>
                <hr>
                <div v-if="face.length > 0" class="uk-text-right">
                  <label class="uk-form-label"></label>
                  <b-button v-on:click="exportCSVFace">CSV</b-button>
                </div>
                <TableCard title="Consulta Reconocimiento Facial"
                           :haveFilter="true" :columns="columnsFaceRecognitionTable" :info="face"
                           detailKey="transactionid" :configFilter="configFilterFaceRecognition"
                           :tableType="2"></TableCard>
                <hr>
                <div v-if="login.length > 0" class="uk-text-right">
                  <label class="uk-form-label"></label>
                  <b-button v-on:click="exportCSVLogin">CSV</b-button>
                </div>
                <TableCard title="Consulta Autenticación"
                           :haveFilter="true" :columns="columnsLoginTable" :info="login"
                           detailKey="log_id" :configFilter="configFilterLogin" :tableConfig="tableConfig"></TableCard>
                <hr>
                <div v-if="creacion.length > 0" class="uk-text-right">
                  <label class="uk-form-label"></label>
                  <b-button v-on:click="exportCSVCreacion">CSV</b-button>
                </div>
                <TableCard title="Consulta Creación de Cuenta"
                           :haveFilter="true" :columns="columnsCreateAccountTable" :info="creacion"
                           detailKey="log_id_creacion" :configFilter="configFilterCreateAccount"
                           :tableConfig="tableConfig"></TableCard>
                <hr>
                <div v-if="cambio.length > 0" class="uk-text-right">
                  <label class="uk-form-label"></label>
                  <b-button v-on:click="exportCSVCambio">CSV</b-button>
                </div>
                <TableCard title="Consulta Cambio de Contraseña"
                           :haveFilter="true" :columns="columnsChangePasswordTable" :info="cambio"
                           detailKey="log_id_cambio" :configFilter="configFilterChangePassword" :tableConfig="tableConfig"></TableCard>
                <hr>
                <div v-if="recuperar.length > 0" class="uk-text-right">
                  <label class="uk-form-label"></label>
                  <b-button v-on:click="exportCSVRecuperacion">CSV</b-button>
                </div>
                <TableCard title="Consulta Recuperación"
                           :haveFilter="true" :columns="columnsChangePasswordTable" :info="recuperar"
                           detailKey="log_id_cambio" :configFilter="configFilterChangePassword" :tableConfig="tableConfig"></TableCard>
                <hr>
                <div v-if="registraduria.length > 0" class="uk-text-right">
                  <b-button v-on:click="exportCSVRegistraduria">CSV</b-button>
                </div>
                <TableCard title="Consulta Registraduria"
                           :haveFilter="true" :columns="columnsRegistraduria" :info="registraduria"
                           detailKey="id" :tableConfig="tableConfig" :configFilter="configFilterRegistraduria"></TableCard>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
      <br>
      <br>
      <br>
    </main-layout>
  </main>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import TableCard from "../../components/cards/TableCard";
import axios from "axios";
import {
  COLUMNS_RECUPERAR_PAGE_TABLE,
  CONFIG_FILTER_CHANGE_PASSWORD,
  COLUMNS_CREATE_ACCOUNT_TABLE,
  CONFIG_FILTER_CREATE_ACCOUNT,
  COLUMNS_FACE_RECOGNITION_TABLE,
  CONFIG_FILTER_FACE_RECOGNITION,
  COLUMNS_ONBOARDING_TABLE_FINAN,
  CONFIG_FILTER_ONBOARDING_FINAN,
  COLUMNS_REGISTRADURIA,
  COLUMNS_LOGIN_PAGE_TABLE,
  CONFIG_FILTER_LOGIN,
  CONFIG_FILTER_REGISTRADURIA,
} from "../../assets/config/constants/TableConstants";
import {mapActions} from "vuex";
import {formatData, getDateRange} from "../../assets/config/utilities";
import {mapState} from "vuex";
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

export default {
  name: "PorUsuario",
  components: {
    MainLayout,
    TableCard
  },
  data() {
    const today = new Date()
    return {
      maxDate: new Date(),
      minDate: new Date(today.getFullYear(), today.getMonth()-6, today.getDay()),
      data: [],
      login: [],
      creacion: [],
      face: [],
      cambio: [],
      recuperar: [],
      registraduria: [],
      isLoading: false,
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      fechaInicial: "",
      fechaFinal: "",
      model: {
        fecha: [],
        cedula: null,
        tipo: null,
      },
      validateForm: {
        fecha: {
          message: '',
          type: ''
        },
        cedula: {
          message: '',
          type: ''
        },
        tipo: {
          message: '',
          type: ''
        }
      },
      documentTypes: null,
      columnsChangePasswordTable: COLUMNS_RECUPERAR_PAGE_TABLE,
      configFilterChangePassword: CONFIG_FILTER_CHANGE_PASSWORD,
      columnsCreateAccountTable: COLUMNS_CREATE_ACCOUNT_TABLE,
      configFilterCreateAccount: CONFIG_FILTER_CREATE_ACCOUNT,
      columnsLoginTable: COLUMNS_LOGIN_PAGE_TABLE,
      configFilterLogin: CONFIG_FILTER_LOGIN,
      columnsFaceRecognitionTable: COLUMNS_FACE_RECOGNITION_TABLE,
      configFilterFaceRecognition: CONFIG_FILTER_FACE_RECOGNITION,
      columnsOnboardingTable: COLUMNS_ONBOARDING_TABLE_FINAN,
      configFilterOnboarding: CONFIG_FILTER_ONBOARDING_FINAN,
      columnsRegistraduria: COLUMNS_REGISTRADURIA,
      configFilterRegistraduria:CONFIG_FILTER_REGISTRADURIA,
      user: null,
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.apiTiposDocumento().then((res) => {
      let opTipos = res.filter(tipo => {
        return tipo.Dashboard == true
      }).map(function (tipo) {
        return ({
          tipo: tipo.code.toLowerCase(),
          text: tipo.Title,
          alfa: tipo.AlfaNumerico,
        })
      }).sort((x, y) => x.text.localeCompare(y.text));

      this.documentTypes = opTipos
    });
  },
  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  methods: {
    async apiTiposDocumento() {
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento+'/tiposdedocumento',
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
        },
      }).then((res) => {
        return res.data;
      }).catch((error) => {
          console.error(error)
      })
    },
    cambioTipo() {
      let alfaNumerico = this.$refs.tipoSelect.$refs.select.selectedOptions[0].getAttribute('alfanumerico')
      let pattern = '[0-9]+';
      let oninput = "this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\..*)\\./g, '$1');"
      if (alfaNumerico == 'true') {
          pattern = '[0-9A-Za-zÑñ]+';
          oninput = "this.value = this.value.replace(/[^0-9A-Za-zÑñ]/g, '');"
      }
      this.$refs.numeroDocumento.$refs.input.value = '';
      this.$refs.numeroDocumento.$refs.input.pattern = pattern
      this.$refs.numeroDocumento.$refs.input.setAttribute('oninput', oninput);
    },
    ...mapActions(['_axios']),
    getTransactions() {
      this.fechaInicial = this.model.fecha[0];
      this.fechaFinal = this.model.fecha[1];
      if (!this.validate())
        return;
      this.isLoading = true;
      const options = {
        method: "post",
        uri: "boards/transacionesporusuario",
        data: {
          Cedula: `${this.model.cedula}`,
          FechaConsulta: getDateRange(this.model.fecha),
          Tipo: this.model.tipo,
        },
      };
      this._axios(options).then(res => {
        this.data = res.data.info.sop;
        this.creacion = formatData(res.data.info.creacion);
        this.login = formatData(res.data.info.login);
        this.face = formatData(res.data.info.face);
        this.cambio = formatData(res.data.info.cambio);
        this.recuperar = formatData(res.data.info.recuperar);
        this.registraduria = formatData(res.data.info.registraduria);
        this.user = {
          document: this.model.cedula,
          documentType: this.model.tipo.toUpperCase()
        }
        this.model = {
          fecha: [],
          cedula: null
        }
      }).finally(() => {
        this.cancelarLoading();
      })
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    exportCSV() {
      this.exportFile("exportCSVSOPUser");
    },
    exportCSVFace(){
      this.exportFile("exportCSVFaceUser");
    },
    exportCSVLogin(){
      this.exportFile("exportCSVLoginUser");
    },
    exportCSVCreacion(){
      this.exportFile("exportCSVCreateUser");
    },
    exportCSVCambio(){
      this.exportFile("exportCSVCambioUser");
    },
    exportCSVRecuperacion(){
      this.exportFile("exportCSVRecuperarUser");
    },
    exportCSVRegistraduria(){
      this.exportFile("exportCSVRegistraduriaUser");
    },
    exportExcel(){
      this.exportFile("exportEXCELUser");
    },
    exportFile(type) {
      if (this.fechaInicial !== "") {
        let fechaInicial = this.convertFecha(this.fechaInicial);
        let fechaFinal = this.convertFecha(this.fechaFinal);
        let usuario = this.user.documentType+this.user.document
        usuario = usuario.toLowerCase();
        let tipo = (this.user.documentType).toLowerCase();
        let doc = this.user.document;
        let routeData = "";
        if(type != 'exportCSVSOPUser'){
          routeData = `${this._HOST_SERVE}/export/${type}/${tipo}/${doc}/${fechaInicial}/${fechaFinal}`;
        }else{
          routeData = `${this._HOST_SERVE}/export/${type}/${usuario}/${fechaInicial}/${fechaFinal}`;
        }
        window.open(routeData, "_blank");
      }
    },
    convertFecha: function (fecha) {
      let date = new Date(fecha).toISOString().slice(0, 10);
      return date;
    },
    validate() {
      if (this.model.fecha.length === 0) {
        this.validateForm.fecha.message = 'Debe ingresar un rango de fechas válido';
        this.validateForm.fecha.type = 'is-danger';
        return false;
      }
      if (this.model.cedula === null) {
        this.validateForm.cedula.message = 'Debe ingresar un número de documento válido';
        this.validateForm.cedula.type = 'is-danger';
        return false;
      }
      if (this.model.tipo === null) {
        this.validateForm.tipo.message = 'Debe ingresar una tipo de documento válido';
        this.validateForm.tipo.type = 'is-danger';
        return false;
      }
      this.validateForm.fecha.message = '';
      this.validateForm.fecha.type = '';

      this.validateForm.cedula.message = '';
      this.validateForm.cedula.type = '';

      this.validateForm.tipo.message = '';
      this.validateForm.tipo.type = '';
      return true;
    }
  }
}
</script>